.gallery-img{
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
    height: 100%;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.gallery-title{
    li {
        display: inline;
        text-align: justify;
        font-size: 48px;
    }
}


.workCard{
    display: inline-grid;
    position: relative;
    background: white;
    width: 48%;
    min-height: 300px;
    margin: 0 10px 10px 0;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    &-img{
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 5px 5px 0 0;
    }
    &-title{
        width: 100%;
        padding: 20px;
    }
    a {
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
    }
    &:hover{
        @include box-shadow(0px 0px 10px 4px#00000036);
        transform: scale(1.01);
        transition: ease-in-out 0.2s;
    }
}

@media screen and (max-width: 992px) {
    .workCard{
        width: 96%;
    }
}
