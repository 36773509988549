
.navigation-container{
    padding: 20px;
    width: 80%;
    top: initial;
    bottom: 10px;
    left: 10%;
    right: 10%;
    position: absolute;
    z-index: 10;
    
    &__nav-element{
        padding: 20px 20px 20px 20px;
        color: white;
        list-style-type: none;
        font-weight: 700;
        display: inline;
    }
    a{
        text-decoration: none;
    }
    
    &__nav-element:hover{
        cursor: pointer;
        text-decoration: none;
        //color: black;
        //background-color: #F5F5F5;
        box-shadow: 0px 0px 75px 0px rgba(0,0,0,0.9);
        border-radius: 5px;
        transition: ease-in-out .3s;
    }
}

@media screen and (max-width: 992px) {
    .navigation-container{
        z-index: 10;
        bottom: initial;
        top: 0;
        left: 0;
        position: absolute;
        &__nav-element{
            display: block;
        }
    }    
  }