$background:#282828;
$dark: #282828;
$white: #ffffffed;
$red: #F59A9A;
$gray: white;
$blue: #002182f5;

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}