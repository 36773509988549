


.popupwrapper{

    &-specific {
      background: $white;
      //box-shadow: 5px 5px 0px 5px #c5c5c5;
      box-shadow: 0px 0px 75px 0px rgba(0,0,0,0.5);
      border-radius: 10px;
      position: absolute;
      height: 95%;
      top: 2%;
      bottom: 2%;
      left: 2%;
      right: 2%;
      z-index: 999;

      .buttons {
        margin-left: 10px;
        min-width: 200px;
        min-height: 60px;
        border-radius: 5px;
        padding: 20px;
        background-color: $blue;
        border: 4px solid transparent;
    }

    .backButton{
      background-color: white;
      color: #575757;
      border: 1px solid transparent;
      //box-shadow: 2px 2px 0px 2px #c5c5c5;
      @include box-shadow(inset 0 0 20px 0 rgb(74, 74, 74));
      &:hover{
        opacity: 0.7;
        transition: ease-in-out 0.3s;
    }
    }
    .backButton:active{
      margin: 0 -2px -2px 0;
      box-shadow: 0px 0px 0px 0px #c5c5c5;
    }

    ::-webkit-scrollbar {
      width: 20px;
    }
    ::-webkit-scrollbar-track {
      background:  $gray; 
    }
    ::-webkit-scrollbar-thumb {
      background: #cfcdcd; 
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #9b9a9a; 
    }

    &_content{
        height: 530px;
        margin: 20px;
        padding: 0 10px;
        overflow-y: auto; 

        ul{padding: 0;}
        li{list-style-type: none;}

        .buttons {
            margin-left: 0;
        }

        ul {
          margin-top: 30px; 
        }
        li {
          margin-top: 20px;
        }

        &_link{
          font-size: 18px;
          color: $blue;
          padding: 20px 15px 20px 0;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        &_link:hover{
          color: black;
          transition: ease-in-out .3s;
          text-decoration: none;
        }

    }
    &_buttons{
        padding: 0 40px 0 40px;
        position: absolute;
        bottom: 40px;
        right: 20px;
        color: $white;
        &-cta{
            background-color: $dark;
            color: $white;
            &:hover{
                opacity: 0.7;
                transition: ease-in-out 0.3s;
            }
        }

    }
    }


    position: relative;
    width: 100%;
    background: $white;
    //box-shadow: 5px 5px 0px 5px #c5c5c5;
    box-shadow: 0px 0px 75px 0px rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    margin-top: 50px;
    min-height: 660px;
    /*border: 4px solid $gray;*/
    z-index: 999;
    &_header{
        top: 0;
        /*border-bottom: 4px solid $dark;*/
        min-height: 65px;
        padding: 20px 40px 20px 40px;
        background: $gray;
        border-radius: 10px 10px 0 0;
        &_btn{
            display: inline;
            &-1{
                border-radius: 100%;
                width: 40px;
                height: 40px;
                //background-color: $red;
                background-color: #dddddd;
                border: none;
                position: relative;
                img {
                  width: 90%;
                  position: absolute;
                  left: 5%;
                  top: 5%;
                  bottom: 5%;
                  right: 5%;
                }
                &:hover{
                  @include box-shadow(0px 0px 10px 0px  #00000038);
                  transform: scale(1.01);
                  transition: ease-in-out 0.2s;
                }
            }
            &-darkmode{
                border-radius: 100%;
                width: 25px;
                height: 25px;
                background-color: $dark;
                border: 4px solid $dark;
                margin-left: 10px;
            }
        }
        &_title{
            float: right;
            margin-top: 5px;
            h1{
                margin: 0;
            }
        }
    }
    .buttons {
        margin-left: 10px;
        min-width: 200px;
        min-height: 60px;
        border-radius: 5px;
        //padding: 20px;
        background-color: $blue;
        border: 4px solid transparent;
        //box-shadow: 2px 2px 0px 2px #000000;
        //box-shadow: 0px 0px 75px 0px rgba(0, 0, 0, 0.1);
        @include box-shadow(0px 0px 10px 0px #00000042);
    }

    .buttons:active{
      //margin: 0 -2px -2px 10px;
      border: 4px solid transparent;
      box-shadow: 0px 0px 0px 0px transparent;
    }

    .backButton{
      background-color: white;
      color: #575757;
      border: 1px solid transparent;
     //box-shadow: 2px 2px 0px 2px #c5c5c5;
      //box-shadow: 0px 0px 75px 0px rgba(0, 0, 0, 0.1);
      @include box-shadow(0px 0px 10px 0px #00000042);
      &:hover{
        //opacity: 0.7;
        @include box-shadow(0px 0px 10px 4px#00000036);
        transform: scale(1.01);
        transition: ease-in-out 0.2s;
    }
    }
    .backButton:active{
      //margin: 0 -2px -2px 0;
      box-shadow: 0px 0px 0px 0px transparent;
    }


  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background:  $gray; 
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb {
    background: #cfcdcd; 
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #9b9a9a; 
  }
  &_searchbar{
    margin: 10px 0 -30px 40px;
    padding: 0 0 0 15px;
    width: 30%;

    &_form {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
  }
    &_content{
        height: 410px;
        margin: 40px;
        padding: 15px;
        overflow-y: auto; 

        ul{padding: 0;}
        li{list-style-type: none;}

        .buttons {
            margin-left: 0;
        }

        ul {
          margin-top: 30px; 
        }
        li {
          margin-top: 20px;
        }

        &_link{
          font-size: 18px;
          color: $dark;
          padding: 20px 15px 20px 0;
          font-weight: 600;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        &_link:hover{
          opacity: 0.7;
          transition: ease-in-out .3s;
          text-decoration: none;
        }

    }
    &_buttons{
        padding: 0 40px 0 40px;
        position: absolute;
        bottom: 40px;
        right: 20px;
        color: $white;
        &-cta{
            background-color: $dark;
            color: $white;
            &:hover{
              //opacity: 0.7;
              @include box-shadow(0px 0px 10px 0px  #000000c7);
              transform: scale(1.01);
              transition: ease-in-out 0.2s;
            }
        }

    }
}





@media screen and (max-width: 992px) {
    .popupwrapper{
        margin-top: 20px;
        min-height: 700px;
        .buttons {
            min-width: 84px;
            min-height: 20px;
            margin-bottom: 10px;
            padding: 15px;
        }

        &_searchbar{
          margin: 10px 0 15px 20px;
          padding: 0;
          width: 80%;
        }

      ::-webkit-scrollbar {
        width: 10px;
      }

      &_header{
        border-radius: 10px 10px 0 0;
      }
        &_content{
            height: 500px;
            margin: 10px;
            padding: 10px;
            position: relative
        }
        &_buttons{
            padding: 0 10px 0 10px;
            bottom: 10px;
            right: 10px;
        }  
  }
}


