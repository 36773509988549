.contact-form{
    input, textarea{
        border-radius: 0;
        background-color: rgb(237, 237, 237);
        border: transparent;
        border-bottom: 4px solid black;
        padding: 30px;
        border-radius: 5px;
        margin: 0;
    }
    textarea{
        min-height: 200px;
    }
    .error {
        display: block;
        &__hide {
            display: none;
        }
    }
}