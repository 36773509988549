
.accordion{
    &-header{   
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 10px 5px 15px;
        color: white;
        padding: 5px 10px 5px 10px;
        background-color: #002182f5;
        position: relative;
        border-radius: 5px;
        &_arrow{
            float: right;
            transform: rotate(90deg);
        }
    }
    &-header:hover{
        cursor: pointer;
        //opacity: 0.7;
        @include box-shadow(0px 0px 10px 0px  #000000c7);
        transform: scale(1.01);
        transition: ease-in-out 0.2s;
    }
    &-content{
        margin: -10px 10px 5px 15px;
        border: 2px solid $blue;
        color: black;
        padding: 40px;
        border-radius: 0 0 5px 5px;
        &__hide{
            display: none;
        }
    }
}

.cv_content {
    margin-top: 40px;
}